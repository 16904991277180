<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <button @click="rotate">MOAR!!!</button>
    <br/>
    <br/>
    <img :src="imageUrl" style="max-width: 500px" />
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    imageUrl() {
      return `/img/simon-${this.index}.png`
    }
  },
  data() {
    return {
      index: 1,
    }
  },
  methods: {
    rotate() {
      const MAX = 10;
      if (this.index >= MAX) {
        this.index = 1;
        return;
      } 
      this.index++;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
